import React from "react";
import styled from "styled-components";

function ApplicationSecurity() {
  return (
    <>
      <Container>
        <H1>Application Security Policy</H1>
        <Wrapper>
          <Ol>
            <LiOl>
              <LiTitleColored>Purpose</LiTitleColored>
              <LiText>The purpose of this policy is to ensure the security of the website and its data by applying best practices in application security. The policy aims to minimize vulnerabilities and protect the site from attacks such as data breaches, unauthorized access, and other malicious activities.</LiText>
            </LiOl>
            <LiOl>
              <LiTitleColored>Scope</LiTitleColored>
              <LiText>This policy applies to all employees, contractors, and third-party partners who are responsible for developing, maintaining, or interacting with the website and its data.</LiText>
            </LiOl>
            <LiOl>
            <LiTitleColored>Security Standards</LiTitleColored>
            <LiTitle>a. Access Control</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>Implement multi-factor authentication (MFA) for all administrative accounts.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Use role-based access control (RBAC) to restrict access to sensitive data and critical functionalities.</LiText>
                </LiUl>
              </Ul>
              <LiTitle>b. Data Protection</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>All data must be encrypted both in transit (using HTTPS/TLS) and at rest.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Sensitive information such as passwords must be hashed using secure algorithms (e.g., bcrypt, Argon2).</LiText>
                </LiUl>
              </Ul>
              <LiTitle>c. Vulnerability Management</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>Regularly conduct security assessments, including vulnerability scans and penetration testing.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Apply patches and updates to all software components (e.g., server, application libraries) as soon as they are available.</LiText>
                </LiUl>
              </Ul>
              <LiTitle>d. Input Validation</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>Implement strong input validation to prevent injection attacks (e.g., SQL injection, cross-site scripting).</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Use prepared statements and parameterized queries in all database interactions.</LiText>
                </LiUl>
              </Ul>
              <LiTitle>e. Secure Coding Practices</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>Follow OWASP Top 10 guidelines to avoid common vulnerabilities in the codebase.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Conduct code reviews and security audits to ensure secure coding standards are being met.</LiText>
                </LiUl>
              </Ul>
              <LiTitle>f. Incident Response</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>In case of a security breach, an incident response plan should be in place, including notifying affected parties, containing the threat, and remediating vulnerabilities.</LiText>
                </LiUl>
              </Ul>
              <LiTitle>g. Logging and Monitoring</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>Enable logging of critical events (e.g., authentication attempts, data access) and monitor these logs for any suspicious activity.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Logs should be stored securely and reviewed periodically.</LiText>
                </LiUl>
              </Ul>
              <LiTitle>h. Third-Party Services</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>Ensure that third-party service providers comply with relevant security standards and regularly assess their security posture.</LiText>
                </LiUl>
              </Ul>
            </LiOl>    
            <LiOl>
              <LiTitleColored>Compliance and Enforcement</LiTitleColored>
              <LiText>Employees, contractors, and third-party partners must comply with this policy. Failure to adhere to these security standards may result in disciplinary action or termination of contracts.</LiText>
            </LiOl>
            <LiOl>
              <LiTitleColored>Review and Updates</LiTitleColored>
              <LiText>This policy must be reviewed and updated regularly, at least once a year, or whenever significant changes are made to the website architecture or security landscape.</LiText>
            </LiOl>                                                                                 
          </Ol>
        </Wrapper>
      </Container>
    </>
  );
}

const Container = styled("section")`
  position: relative;
  margin-top: 9rem;
  margin-bottom: 4rem;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  padding: 0px 60px;
`

const Wrapper = styled("div")``

const H1 = styled("h1")``

const Ol = styled("ol")``

const LiOl = styled("li")`
  margin-bottom: 1.5rem;
`

const Ul = styled("ul")``

const LiUl = styled("li")``

const LiTitle = styled("p")`
  font-weight: bolder;
  margin-bottom: .5rem;
`

const LiTitleColored = styled(LiTitle)`
  color: #685ADD;
`

const LiText = styled("p")`
  font-weight:lighter;
  margin-bottom: .5rem;
`

export default ApplicationSecurity;