import styled from "styled-components";

function SoftwarePrinciples() {
  return (
    <>
    <Container>
      <H1>Software Principle</H1>
      <Wrapper>
        <Ol>
          <LiOl>
            <LiTitleColored>Simplicit</LiTitleColored>
            <LiTitle>Keep the code simple and readable</LiTitle>
            <LiText>Simplicity in software design reduces complexity, making the system easier to understand, maintain, and extend. Write clean, straightforward code that can be easily reviewed and modified by other developers</LiText>
          </LiOl>
          <LiOl>
            <LiTitleColored>Modularit</LiTitleColored>
            <LiTitle>Encapsulate functionality into independent, reusable modules</LiTitle>
            <LiText>Breaking down software into smaller, self-contained modules helps in organizing code logically, promoting reusability, and simplifying debugging and testing processes</LiText>
          </LiOl>
          <LiOl>
            <LiTitleColored>DRY (Don't Repeat Yourself)</LiTitleColored>
            <LiTitle>Avoid redundancy by reusing code wherever possible</LiTitle>
            <LiText>Duplicating code can lead to errors and inconsistencies. By following the DRY principle, you reduce redundancy, making the codebase easier to maintain and update</LiText>
          </LiOl>
          <LiOl>
            <LiTitleColored>SOLID Principle</LiTitleColored>
            <Ul>
                <LiUl>
                    <LiText>
                        <AccentText>Single Responsibility Principle (SRP): </AccentText>
                        Every module/class should have only one responsibility.
                    </LiText>
                </LiUl>
                <LiUl>
                    <LiText>
                        <AccentText>Open/Closed Principle: </AccentText>
                        Software entities should be open for extension but closed for modification.
                    </LiText>
                </LiUl>
                <LiUl>
                    <LiText>
                        <AccentText>Liskov Substitution Principle: </AccentText>
                        Subtypes must be substitutable for their base types without affecting the correctness of the program.
                    </LiText>
                </LiUl>
                <LiUl>
                    <LiText>
                        <AccentText>Interface Segregation Principle: </AccentText>
                        Clients should not be forced to depend on interfaces they do not use.
                    </LiText>
                </LiUl>
                <LiUl>
                    <LiText>
                        <AccentText>Dependency Inversion Principle: </AccentText>
                        High-level modules should not depend on low-level modules; both should depend on abstractions.
                    </LiText>
                </LiUl>
            </Ul>
          </LiOl>
          <LiOl>
            <LiTitleColored>YAGNI (You Aren't Gonna Need It)</LiTitleColored>
            <LiTitle>Do not add functionality until it's necessary</LiTitle>
            <LiText>Avoid building features that are not immediately required. Focus on delivering the current requirements, as unneeded features add complexity and maintenance costs</LiText>
          </LiOl>
          <LiOl>
            <LiTitleColored>KIS (Keep It Simple)</LiTitleColored>
            <LiTitle>Strive for simplicity in both design and implementation</LiTitle>
            <LiText>The KIS principle encourages developers to avoid overcomplicating solutions. Simple solutions tend to be more reliable, easier to debug, and less prone to errors</LiText>
          </LiOl>
          <LiOl>
            <LiTitleColored>Maintainabilit</LiTitleColored>
            <LiTitle>Ensure the software is easy to modify and extend</LiTitle>
            <LiText>Code should be written in a way that makes future maintenance easy, including updates, bug fixes, and feature additions. Clear documentation and adherence to coding standards are essential to maintainability</LiText>
          </LiOl>
          <LiOl>
            <LiTitleColored>Performanc</LiTitleColored>
            <LiTitle>Optimize only when necessary</LiTitle>
            <LiText>While performance is important, premature optimization can lead to unnecessary complexity. Focus on creating a functional and correct system first, then optimize critical parts when they become bottlenecks</LiText>
          </LiOl>
          <LiOl>
            <LiTitleColored>Testabilit</LiTitleColored>
            <LiTitle>Design software to be easy to test</LiTitle>
            <LiText>Code should be written in a modular and decoupled way to facilitate unit and integration testing. Writing automated tests ensures reliability and stability as the software evolves</LiText>
          </LiOl>
          <LiOl>
            <LiTitleColored>Security</LiTitleColored>
            <LiTitle>Incorporate security considerations at every stage of development</LiTitle>
            <LiText>Security should be an integral part of the design process, from writing secure code to implementing secure configurations. Protect sensitive data, validate inputs, and ensure the application can handle security threats</LiText>
          </LiOl>                                                            
        </Ol>
      </Wrapper>
    </Container>
    </>
  )
}

const Container = styled("section")`
  position: relative;
  margin-top: 9rem;
  margin-bottom: 4rem;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  padding: 0px 60px;
`

const Wrapper = styled("div")``

const H1 = styled("h1")``

const Ol = styled("ol")``

const LiOl = styled("li")`
  margin-bottom: 1.5rem;
`

const Ul = styled("ul")``

const LiUl = styled("li")``

const LiTitle = styled("p")`
  font-weight: bolder;
  margin-bottom: .5rem;
`

const LiTitleColored = styled(LiTitle)`
  color: #685ADD;
`

const LiText = styled("p")`
  font-weight:lighter;
  margin-bottom: .5rem;
`

const AccentText = styled("span")`
  font-weight: bold;
`
export default SoftwarePrinciples;