import React from "react";
import styled from "styled-components";


function FAQElement({ title, text, isOpen, toggleFAQ }) {
    return (
        <Paragraph
            $isShowText={isOpen}
        >
            <Headline>
                <HeadlineText>{title}</HeadlineText>
                {
                    isOpen ? 
                    <Icon 
                        src="/img/svg/cross.svg"
                        onClick={toggleFAQ}
                    />
                    :
                    <Icon 
                        src="/img/svg/plus.svg"
                        onClick={toggleFAQ}
                    />
                }
                </Headline>
            {isOpen && 
                <MainText>
                    {text}
                </MainText>
            }
        </Paragraph>
    )
}

const Paragraph = styled("div")`
    display: flex;
    flex-direction: column;
    padding: 1.75em;
    background: ${props => props?.$isShowText ? "#fff" : "transparent"};
    border-radius: ${props => props?.$isShowText ? "2.625rem" : ""};
    margin-bottom: ${props => props?.$isShowText ? "2.625rem" : ""};

    @media (max-width: 768px) {
        padding: .75em;
        margin-bottom: ${props => props?.$isShowText ? "1.5rem" : ""};
    }
`
const Headline = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-grow: 1;
`
const Icon = styled("img")`

`
const HeadlineText = styled("p")`
    color: #1C1C1C;
    font-family: Exo;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`
const MainText = styled("p")`
    color: #000;
    font-family: Exo;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 0;
    margin: 0;
    margin-top: 2rem;

    @media (max-width: 768px) {
        margin-top: 1rem;
        font-size: 1rem;
    }
`

export default FAQElement;