import React, { useState }  from "react";
import styled from "styled-components";
import { useLocalization } from "../../utils/LocalizationContext";

function GetTouch() {
    const { t } = useLocalization()

    const [email, setEmail ] = useState('')
    const [name, setName ] = useState('')
    const [message, setMessage ] = useState('')

    const handleSubmit = async (event) => {
        event.preventDefault(); 
        const url = "https://mail-api-reciver.azurewebsites.net:443/api/Mail/triggers/When_a_HTTP_request_is_received/invoke?api-version=2022-05-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=m7Z7OYMSzgXuui7TbpD9j3q0lIWzZeDUglchk5dhEKQ";
        
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message,
                }),
              })

              if (!response.ok) {
                console.error("There was an error sending your message, please try again later.")
              } else {
                setEmail("")
                setName("")
                setMessage("")
                console.log("Success")
              }
        } catch (error) {
            console.error("There was an error sending your message, please try again later.")
        }
      };

    const isButtonDisabled = !email || !name || !message

    return (
        <Section>
            <Title>{t("Get in touch with ENDOless")}</Title>
            <Form>
                <Input
                    type="text"
                    placeholder={t("Enter your name*")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Input
                    type="email"
                    placeholder={t("Enter your email*")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextArea 
                    placeholder={t("Enter your message*")}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={10}
                />
                <Button
                    type="button"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                >
                    {t("Contact us")}
                </Button>
            </Form>
        </Section>
    )
}

const Section = styled("section")`
    margin: 8rem 2.5rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        margin: 3rem 1.5rem;
        flex-direction: column;
    }
`
const Title = styled("p")`
    color: #1C1C1C;
    font-family: Exo;
    font-size: 2.84425rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.125rem;
    letter-spacing: -0.125rem;

    @media (max-width: 768px) {
        font-size: 1.75rem;
    }
`
const Form = styled("form")`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 6rem;
    gap: 2rem;

    @media (max-width: 768px) {
        margin-left: 0;
        align-items: flex-start;
    }
`
const Input = styled("input")`
    padding: 1.2em;
    border-radius: 2.625rem;
    border: 1px solid rgba(104, 90, 221, 0.28);
    background: #FFF;
    width: 75%;

        &:focus, &:focus-visible, &:active {
            border-color: #685ADD; 
            outline: none;
        }

        &::placeholder {
            color: #CCC8C8;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.375rem; /* 183.333% */
        }
`

const TextArea = styled("textarea")`
    padding: 1.2em;
    border-radius: 2rem;
    border: 1px solid rgba(104, 90, 221, 0.28);
    background: #FFF;
    width: 75%;
    resize: none;

    &:focus, &:focus-visible, &:active {
        border-color: #685ADD; 
        outline: none;
    }

    &::placeholder {
        color: #CCC8C8;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.375rem; /* 183.333% */
    }
`
const Button = styled("button")`
    padding: 1em 1.75em;
    border-radius: 2.625rem;
    background: #685ADD;
    border-width: 0;
    border: none;
    color: #FFF;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem;
    align-self: flex-end;

    &:disabled {
        opacity: 0.81;
        background: #685ADD;
    }

    &:not([disabled]):hover {
        border-radius: 2.625rem;
        border: 1px solid #685ADD;
        background: #FCFAF6;
        color: #685ADD;
    }

    @media (max-width: 768px) {
        align-self: flex-start;
    }
`
export default GetTouch;