export const translations = {
    en: {
      "Get in touch": "Get in touch",
      "Living": "Living",
      "with endometriosis": "with endometriosis",
      "isn’t your entire story": "isn’t your entire story",
      "We believe every woman with endometriosis and other chronic gynecological conditions deserves more than just survival.": "We believe every woman with endometriosis and other chronic gynecological conditions deserves more than just survival.",
      "You deserve to thrive.": "You deserve to thrive.",
      "ENDOless isn’t just another app — it’s your guide to living beyond the limits of endometriosis. One symptom at a time.": "",
      "Join the waitlist": "",
      "Partners": "Partners", 
      "Endometriosis affects": "Endometriosis affects", 
      "more women than diabetes": "more women than diabetes", 
      "yet it remains a hidden condition —": "yet it remains a hidden condition —", 
      "ignored, misunderstood, ": "ignored, misunderstood, ",
      "and": "and", 
      "underdiagnosed": "underdiagnosed", 
      "190M": "190M",
      "Women worldwide": "Women worldwide", 
      "live with endometriosis": "live with endometriosis",
      "€6,298": "€6,298",
      "The annual": "The annual", 
      "Socioeconomic impact per woman.": "Socioeconomic impact per woman.", 
      "7 Years": "7 Years",
      "It takes": "It takes",
      "to receive a diagnosis": "to receive a diagnosis",
      "0 Cures": "0 Cures",
      "Lead to healing": "Lead to healing",
      "It’s time to ": "It’s time to ",
      "change that.": "change that.",
      "Endometriosis doesn’t just sit in one part of your life — ": "Endometriosis doesn’t just sit in one part of your life — ",
      "it’s ": "it’s ",
      "always there": "always there",
      "reshaping everything": "reshaping everything",
      "Social life": "Social life",
      "You cancel plans—again—because the pain is too much, and you just can’t fake being okay anymore. People don’t get it because you “don’t look sick.”": "You cancel plans—again—because the pain is too much, and you just can’t fake being okay anymore. People don’t get it because you “don’t look sick.”",
      "Professional Life": "Professional Life",
      "You’re juggling pain, exhaustion, and trying to keep up with everyone else. You worry about being seen as unreliable, even though you’rer trying twice as hard.": "You’re juggling pain, exhaustion, and trying to keep up with everyone else. You worry about being seen as unreliable, even though you’rer trying twice as hard.",
      "Fertility": "Fertility",
      "Whether you’re trying for a baby or not, the uncertainty can weigh heavy. 50% of infertility cases are connected to chronic conditions.  it’s a heartbreak and constant ‘what-if’ you didn’t ask for but have to carry.": "Whether you’re trying for a baby or not, the uncertainty can weigh heavy. 50% of infertility cases are connected to chronic conditions.  it’s a heartbreak and constant ‘what-if’ you didn’t ask for but have to carry.",
      "Mental Health": "Mental Health",
      "Your confidence, joy, and energy feel like they’ve disappeared. Endometriosis steals the sense of self-love you once had, leaving you drained, frustrated, and disconnected from the vibrant person you used to be.": "Your confidence, joy, and energy feel like they’ve disappeared. Endometriosis steals the sense of self-love you once had, leaving you drained, frustrated, and disconnected from the vibrant person you used to be.",
      "I haven’t been able to have a family of my own — no children, no husband, which has strongly affected me emotionally. Because of endometriosis I couldn't get the life I have always been dreaming about": "I haven’t been able to have a family of my own — no children, no husband, which has strongly affected me emotionally. Because of endometriosis I couldn't get the life I have always been dreaming about",
      "Endo Warrior, 20 years with the diagnosis": "Endo Warrior, 20 years with the diagnosis",
      "Your journey. ": "Your journey. ",
      "Your power.": "Your power.",
      "Every woman deserves to live a life free from constant pain and lifestyle limitations. And it starts with better understanding, tracking, and managing her condition.": "Every woman deserves to live a life free from constant pain and lifestyle limitations. And it starts with better understanding, tracking, and managing her condition.",
      "ENDOless is designed to help you regain control": "ENDOless is designed to help you regain control",
      "over your life.": "over your life.",
      "By tracking symptoms, cycles, and triggers, we help you understand your body and provide insights tailored to your unique journey with endometriosis, PCOS, adenomyosis and other gynecological conditions.": "By tracking symptoms, cycles, and triggers, we help you understand your body and provide insights tailored to your unique journey with endometriosis, PCOS, adenomyosis and other gynecological conditions.",
      "How it works": "How it works",
      "in 4 simple steps": "in 4 simple steps",
      "Track": "Track",
      "Monitor pain intensity, mood, lifestyle choices and other symptoms": "Monitor pain intensity, mood, lifestyle choices and other symptoms",
      "Predict & Plan": "Predict & Plan",
      "Receive personalized AI-predictions for your pain and other symptoms and plan your life accordingly": "Receive personalized AI-predictions for your pain and other symptoms and plan your life accordingly",
      "Identify": "Identify",
      "Discover what really helps to minimize symptoms   and works best for you": "Discover what really helps to minimize symptoms   and works best for you",
      "Report": "Report",
      "Generate detailed reports to share with your doctor": "Generate detailed reports to share with your doctor",
      "Your journey,": "Your journey,",
      "Your ": "Your ",
      "questions": "questions",
      " — answered": " — answered",
      "Is ENDOless only for women with a formal diagnosis?": "Is ENDOless only for women with a formal diagnosis?",
      "Not at all! Whether you’ve been officially diagnosed or just suspect you may have endometriosis or another gynecological condition, ENDOless can help you understand your body better and prepare for conversations with healthcare providers, potentially leading to a faster diagnosis.": "Not at all! Whether you’ve been officially diagnosed or just suspect you may have endometriosis or another gynecological condition, ENDOless can help you understand your body better and prepare for conversations with healthcare providers, potentially leading to a faster diagnosis.",
      "Is ENDOless focused only on endometriosis?": "",
      "Not at all! Our solution also supports women managing other gynecological chronic conditions like PCOS, adenomyosis, fibroids, and more. ENDOless provides symptom management tools and personalized insights to improve your everyday life.": "Not at all! Our solution also supports women managing other gynecological chronic conditions like PCOS, adenomyosis, fibroids, and more. ENDOless provides symptom management tools and personalized insights to improve your everyday life.",
      "When will ENDOless become available to download?": "When will ENDOless become available to download?",
      "The first version of the app will be available for download in early 2025. We’ll continue rolling out updates and introducing new features regularly to expand the app’s capabilities.": "The first version of the app will be available for download in early 2025. We’ll continue rolling out updates and introducing new features regularly to expand the app’s capabilities.",
      "Can I use ENDOless without an Apple Watch?": "Can I use ENDOless without an Apple Watch?",
      "Yes! ENDOless integrates with Apple Health, but you don’t need an Apple Watch to track your symptoms or access personalized insights.": "Yes! ENDOless integrates with Apple Health, but you don’t need an Apple Watch to track your symptoms or access personalized insights.",
      "How does ENDOless help with my doctor’s appointments?": "How does ENDOless help with my doctor’s appointments?",
      "Our detailed health reports summarize your symptoms, triggers, and patterns, making it easier to communicate with your doctor and save time during consultations.": "Our detailed health reports summarize your symptoms, triggers, and patterns, making it easier to communicate with your doctor and save time during consultations.",
      "Is ENDOless available outside France?": "Is ENDOless available outside France?",
      "ENDOless was built in France but is designed for women worldwide. We are gradually rolling out the app, so check availability in your region.": "ENDOless was built in France but is designed for women worldwide. We are gradually rolling out the app, so check availability in your region.",
      "Is this app a replacement for medical treatment?": "Is this app a replacement for medical treatment?",
      "No. ENDOless is a management tool that helps you understand and track your condition while improving your quality of life with personalized insights. It’s designed to complement, not replace, medical care.": "No. ENDOless is a management tool that helps you understand and track your condition while improving your quality of life with personalized insights. It’s designed to complement, not replace, medical care.",
      "Get in touch with ENDOless": "Get in touch with ENDOless",
      "Enter your name*": "Enter your name*",
      "Enter your email*": "Enter your email*",
      "Enter your message*": "Enter your message*",
      "Contact us": "Contact us",
      "personalized": "personalized",
      "endometriosis": "endometriosis",
      "journey": "journey",
      "Product": "Product",
      "Team": "Team",
      "More": "More",
      "Let's chat": "Let's chat",
      "Software Principles": "Software Principles",
      "Application Security": "Application Security",
      "ENDOless. Made with love in Paris ❤️": "ENDOless. Made with love in Paris ❤️",
      "Legal Information": "Legal Information",
    },
    fr: {
      "Get in touch": "Entrer en contact",
      "Living": "Vivre",
      "with endometriosis": "avec l'endométriose",
      "isn’t your entire story": "n'est pas toute votre histoire",
      "We believe every woman with endometriosis and other chronic gynecological conditions deserves more than just survival.": "Nous croyons que chaque femme atteinte d'endométriose et d'autres affections gynécologiques chroniques mérite plus que la simple survie.",
      "You deserve to thrive.": "Vous méritez de vous épanouir.",
      "ENDOless isn’t just another app — it’s your guide to living beyond the limits of endometriosis. One symptom at a time.": "ENDOless n'est pas qu'une simple application — c'est votre guide pour vivre au-delà des limites de l'endométriose. Un symptôme à la fois.",
      "Join the waitlist": "Rejoignez la liste d'attente",
      "Partners": "Partenaires",
      "Endometriosis affects": "L'endométriose touche", 
      "more women than diabetes": "plus de femmes que le diabète", 
      "yet it remains a hidden condition —": "pourtant elle reste une condition cachée — ", 
      "ignored, misunderstood, ": "ignorée, incomprise ",
      "and": "et", 
      "underdiagnosed": "sous-diagnostiquée", 
      "190M": "190M",
      "Women worldwide": "de femmes", 
      "live with endometriosis": "dans le monde vivent avec l’endométriose",
      "€6,298": "€6,298",
      "The annual": "l’impact", 
      "Socioeconomic impact per woman.": "socio-économique annuel par femme", 
      "7 Years": "7 Ans", 
      "It takes": "Le délai moyen",
      "to receive a diagnosis": "pour obtenir un diagnostic",
      "0 Cures": "0 traitement",
      "Lead to healing": "aucune solution curative",
      "It’s time to ": "Il est temps de ",
      "change that.": "changer cela.",
      "Endometriosis doesn’t just sit in one part of your life — ": "L'endométriose ne se limite pas à une seule partie de votre vie — ",
      "it’s ": "elle est ",
      "always there": "toujours là",
      "reshaping everything": "remodèle tout",
      "Social life": "Vie sociale",
      "You cancel plans—again—because the pain is too much, and you just can’t fake being okay anymore. People don’t get it because you “don’t look sick.”": "Vous annulez des plans — encore une fois — parce que la douleur est trop forte, et vous ne pouvez plus faire semblant d'aller bien. Les gens ne comprennent pas parce que vous “n'avez pas l'air malade.”",
      "Professional Life": "Vie professionnelle",
      "You’re juggling pain, exhaustion, and trying to keep up with everyone else. You worry about being seen as unreliable, even though you’rer trying twice as hard.": "Vous jongler entre arrêts maladie, télétravail quand c'est possible, et essayez de suivre le rythme malgré la douleur et l'épuisement. Même avec la reconnaissance en ALD, il est difficile d'expliquer votre situation au travail et vous craignez d'être perçue comme peu fiable.",
      "Fertility": "Fertilité",
      "Whether you’re trying for a baby or not, the uncertainty can weigh heavy. 50% of infertility cases are connected to chronic conditions.  it’s a heartbreak and constant ‘what-if’ you didn’t ask for but have to carry.": "Que vous essayiez d’avoir un enfant ou non, l’incertitude peut être difficile à supporter. 50 % des cas d’infertilité sont liés à des maladies chroniques. C’est un chagrin et un « et si » constant que vous n’avez pas choisi, mais que vous devez porter.",
      "Mental Health": "Santé mentale",
      "Your confidence, joy, and energy feel like they’ve disappeared. Endometriosis steals the sense of self-love you once had, leaving you drained, frustrated, and disconnected from the vibrant person you used to be.": "Votre confiance, votre joie et votre énergie semblent avoir disparu. L'endométriose vole l'amour-propre que vous aviez autrefois, vous laissant épuisée, frustrée et déconnectée de la personne dynamique que vous étiez.",
      "I haven’t been able to have a family of my own — no children, no husband, which has strongly affected me emotionally. Because of endometriosis I couldn't get the life I have always been dreaming about": "Je n’ai pas pu fonder ma propre famille — pas d’enfants, pas de mari, ce qui m’a profondément affectée émotionnellement. À cause de l’endométriose, je n’ai pas pu vivre la vie dont j’ai toujours rêvé.",
      "Endo Warrior, 20 years with the diagnosis": "Guerrière de l’Endo, 20 ans avec ce diagnostic",
      "Your journey. ": "Votre parcours. ",
      "Your power.": "Votre pouvoir.",
      "Every woman deserves to live a life free from constant pain and lifestyle limitations. And it starts with better understanding, tracking, and managing her condition.": "Chaque femme mérite de vivre une vie sans douleur constante et sans limitations de style de vie. Et cela commence par une meilleure compréhension, un meilleur suivi et une meilleure gestion de son état.",
      "ENDOless is designed to help you regain control": "ENDOless est conçu pour vous aider à reprendre le contrôle",
      "over your life.": "de votre vie.",
      "By tracking symptoms, cycles, and triggers, we help you understand your body and provide insights tailored to your unique journey with endometriosis, PCOS, adenomyosis and other gynecological conditions.": "En suivant les symptômes, les cycles et les déclencheurs, nous vous aidons à comprendre votre corps et fournissons des informations adaptées à votre parcours unique avec l'endométriose, le SOPK, l'adénomyose et d'autres affections gynécologiques.",
      "How it works": "Comment ça marche",
      "in 4 simple steps": "en 4 étapes simples",
      "Track": "Suivre",
      "Monitor pain intensity, mood, lifestyle choices and other symptoms": "Surveillez l'intensité de la douleur, l'humeur, les choix de vie et autres symptômes",
      "Predict & Plan": "Prédire & Planifier",
      "Receive personalized AI-predictions for your pain and other symptoms and plan your life accordingly": "Recevez des prédictions IA personnalisées pour votre douleur et autres symptômes et planifiez votre vie en conséquence",
      "Identify": "Identifier",
      "Discover what really helps to minimize symptoms   and works best for you": "Découvrez ce qui aide vraiment à minimiser les symptômes et ce qui fonctionne le mieux pour vous",
      "Report": "Rapporter",
      "Generate detailed reports to share with your doctor": "Générez des rapports détaillés à partager avec votre médecin",
      "Your journey,": "Votre parcours,",
      "Your ": "Vos ",
      "questions": "questions",
      " — answered": "— répondues",
      "Is ENDOless only for women with a formal diagnosis ?": "ENDOless est-il uniquement pour les femmes avec une reconnaissance ALD ?",
      "Not at all! Whether you’ve been officially diagnosed or just suspect you may have endometriosis or another gynecological condition, ENDOless can help you understand your body better and prepare for conversations with healthcare providers, potentially leading to a faster diagnosis.": "Pas du tout ! Que vous ayez déjà une reconnaissance en ALD-31, un diagnostic officiel, ou que vous soupçonniez simplement avoir de l'endométriose ou une autre affection gynécologique, ENDOless peut vous aider à mieux comprendre votre corps et à préparer vos consultations avec les professionnels de santé. Notre objectif est de vous accompagner dans votre parcours de soins.",
      "Is ENDOless focused only on endometriosis ?": "ENDOless est-il concentré uniquement sur l'endométriose ?",
      "Not at all! Our solution also supports women managing other gynecological chronic conditions like PCOS, adenomyosis, fibroids, and more. ENDOless provides symptom management tools and personalized insights to improve your everyday life.": "   Pas du tout ! Notre solution aide également les femmes qui gèrent d'autres affections gynécologiques chroniques comme le SOPK, l'adénomyose, les fibromes et plus encore. ENDOless fournit des outils de gestion des symptômes et des informations personnalisées pour améliorer votre quotidien.",
      "When will ENDOless become available to download ?": "Quand ENDOless sera-t-il disponible au téléchargement ?",
      "The first version of the app will be available for download in early 2025. We’ll continue rolling out updates and introducing new features regularly to expand the app’s capabilities.": "La première version de l'application sera disponible au téléchargement début 2025. Nous continuerons à déployer des mises à jour et à introduire de nouvelles fonctionnalités régulièrement pour étendre les capacités de l'application.",
      "Can I use ENDOless without an Apple Watch ?": "Puis-je utiliser ENDOless sans une Apple Watch ?",
      "Yes! ENDOless integrates with Apple Health, but you don’t need an Apple Watch to track your symptoms or access personalized insights.": "Oui ! ENDOless s'intègre avec Apple Health, mais vous n'avez pas besoin d'une Apple Watch pour suivre vos symptômes ou accéder à des insights personnalisés.",
      "How does ENDOless help with my doctor’s appointments ?": "Comment ENDOless peut-il m’aider lors de mes rendez-vous médicaux ?",
      "Our detailed health reports summarize your symptoms, triggers, and patterns, making it easier to communicate with your doctor and save time during consultations.": "Nos rapports de santé détaillés résument vos symptômes, déclencheurs et tendances, ce qui facilite la communication avec votre médecin et vous fait gagner du temps lors des consultations.",
      "Is ENDOless available outside France ?": "ENDOless est-il disponible en dehors de la France ?",
      "ENDOless was built in France but is designed for women worldwide. We are gradually rolling out the app, so check availability in your region.": "ENDOless a été conçu en France, mais est destiné aux femmes du monde entier. Nous déployons progressivement l'application, vérifiez donc sa disponibilité dans votre région.",
      "Is this app a replacement for medical treatment ?": "Cette application remplace-t-elle un traitement médical ?",
      "No. ENDOless is a management tool that helps you understand and track your condition while improving your quality of life with personalized insights. It’s designed to complement, not replace, medical care.": "Non. ENDOless est un outil de gestion qui vous aide à comprendre et à suivre votre condition tout en améliorant votre qualité de vie grâce à des insights personnalisés. Il est conçu pour compléter, et non remplacer, les soins médicaux.",
      "Get in touch with ENDOless": "Contactez ENDOless",
      "Enter your name*": "Entrez votre nom*",
      "Enter your email*": "Entrez votre email*",
      "Enter your message*": "Entrez votre message*",
      "Contact us": "Nous contacter",
      "personalized": "parcours",
      "endometriosis": "personnalisé dans",
      "journey": "l'endométriose",
      "Product": "Produit",
      "Team": "L'équipe",
      "More": "Plus d'informations",
      "Let's chat": "Discutons-en",
      "Software Principles": "Principes du logiciel",
      "Application Security": "Sécurité des applications",
      "ENDOless. Made with love in Paris ❤️": "ENDOless. Fabriqué avec amour à Paris ❤️",
      "Legal Information": "Informations juridiques",
    },
  };
  