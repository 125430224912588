import React from "react";
import styled from "styled-components";


function Element({ title, isOpen, text, toggleFAQ, styles, isLast }) {
    
    return (
        <Paragraph
            $isLast={isLast}
            style={styles}
        >
            <Headline>
                <HeadlineText>{title}</HeadlineText>
                {
                    isOpen ? 
                    <Icon 
                        src="/img/svg/cross.svg"
                        onClick={toggleFAQ}
                    />
                    :
                    <Icon 
                        src="/img/svg/plus.svg"
                        onClick={toggleFAQ}
                    />
                }
                </Headline>
            {isOpen && 
                <MainText>
                    {text}
                </MainText>
            }
        </Paragraph>
    )
}

const Paragraph = styled("div")`
    display: flex;
    flex-direction: column;
    padding: 1.75em 0;
    border-top: 1px solid;
    border-bottom: ${props => props?.$isLast ? "1px solid;" : ""};

    @media (max-width: 768px) {
        width: 100% !important;
    }
`
const Headline = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
`
const Icon = styled("img")`

`
const HeadlineText = styled("p")`
    color: #1C1C1C;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
`
const MainText = styled("p")`
    color: #000;
    font-family: Exo;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 0;
    margin: 0;
    margin-top: 2rem;
`

export default Element;