// src/components/Footer/index.tsx
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from "styled-components";

function Cookies() {
    const [isShowed, setIsShowed] = useState(false)
    const [cookies, setCookie] = useCookies(['cookieAgreement'])
    const cookieAgreement = cookies.cookieAgreement
    const isHasCookiesInfo = cookieAgreement === 'accepted' || cookieAgreement === 'rejected'

    const handleChange = (status) => {
        setIsShowed(!status)
    }

    const expiresDate = new Date()
    expiresDate.setTime(expiresDate.getTime() + (100 * 365 * 24 * 60 * 60 * 1000)) // 100 years

    const setCookieAgreement = (agreement) => {
        setCookie('cookieAgreement', agreement, { path: '/', expires: expiresDate })
        setCookie('cookieAgreement', agreement, { path: '/', expires: expiresDate });
    }


    const buttonText = isShowed ? "Less information" : "More information"

    return (
        <>
            {!isHasCookiesInfo && (
            <Container>
                <Title>
                    <img 
                        src="/img/svg/cookies.svg" 
                        width='32' height='32'
                        alt=''
                        />
                    <H6>Cookies Policy</H6>
                </Title>
                <Text>To enhance your experience on our website, we use cookies to gather and analyze information about how you interact with our content. Cookies help us improve our services, personalize content, and provide relevant advertising.</Text>
                <ButtonsContainer>
                    <StyledButton
                        onClick={() => setCookieAgreement('rejected')}
                    >
                        Reject Cookies
                    </StyledButton>
                    <StyledButton
                        onClick={() => setCookieAgreement('accepted')}
                    >
                        Accept Cookies
                    </StyledButton>
                </ButtonsContainer>
                {isShowed && (
                    <>
                        <SmallTitle>What Are Cookies?</SmallTitle>
                        <Text>Cookies are small files stored on your device that allow us to remember your preferences and provide a more seamless experience.</Text>
                        <SmallTitle>How We Use Cookies</SmallTitle>
                        <Text>We use cookies to:</Text>
                        <Ul>
                            <Li>Recognize you when you return to our website.</Li>
                            <Li>Understand how you interact with our site.</Li>
                            <Li>Customize your browsing experience.</Li>
                            <Li>Provide targeted and relevant advertisements.</Li>
                        </Ul>
                        <SmallTitle>Your Control Over Cookies</SmallTitle>
                        <Text>You can manage, delete, or disable cookies through your browser settings. Please note that disabling cookies may impact some of our website's functionalities.</Text>
                    </>
                )}
                <TextButton onClick={() => handleChange(isShowed)}>{buttonText}</TextButton>
            </Container>
            )}
        </>
    )
};

const Container = styled("section")`
    position: fixed;
    max-width: 45%;
    bottom: 0;
    left: 0;
    z-index: 5;
    margin-left: 2.5rem;
    margin-bottom: 1.5rem;
    background-color: #FCFAF6;
    padding: 1em;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.41);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.41);

    @media only screen and (max-width: 768px) {
        margin-left: 0;
        max-width: 100%;
    }
`
const Title = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: .5rem 0;
`
const H6 = styled("p")`
    font-size: 24px;
    color: #685ADD;
    margin: 0;
    padding: 0;
    margin-left: .25rem;
`
const Text = styled("p")`
    font-size: 14px;
`
const TextButton = styled("p")`
    font-size: 14px;
    cursor: pointer;

    &:hover {
        color: #685ADD;
        text-decoration-line: underline;
    }
`
const SmallTitle = styled("p")`
    font-size: 16px;
    font-weight: bolder;
    margin: .25rem 0;
`
const Ul = styled("ul")``
const Li = styled("li")`
    font-size: 14px;

    &::marker {
        color: #685ADD;
    }
`
const ButtonsContainer = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
`
const StyledButton = styled("button")`
    background: #685ADD;
    color: rgb(255, 255, 255);
    font-weight: 700;
    width: 100%;
    border: 1px solid rgb(237, 243, 245);
    border-radius: 4px;
    cursor: pointer;
    max-width: 180px;
    transition: 0.3s ease-in-out;

    &:hover {
        color: #685ADD;
        border: 1px solid #685ADD;
        background-color: #F2ECFE;
    }
    &:active {
        color: #685ADD;
        border: 1px solid #685ADD;
        background-color: #F2ECFE;
    }
    &:focus {
        color: #685ADD;
        border: 1px solid #685ADD;
        background-color: #F2ECFE;
    }
`

export default Cookies;

