import React, { useState } from "react";
import styled from "styled-components";
import FAQElement from "./FAQElement";
import { useLocalization } from "../../utils/LocalizationContext";

function Impact() {
    const { t } = useLocalization()
    const [isOpenFaq1, setIsOpenFaq1] = useState(false)
    const [isOpenFaq2, setIsOpenFaq2] = useState(false)
    const [isOpenFaq3, setIsOpenFaq3] = useState(false)
    const [isOpenFaq4, setIsOpenFaq4] = useState(false)

    return (
        <Section>
            <Title>
                {t("Endometriosis doesn’t just sit in one part of your life — ")}
                <br></br>{t("it’s ")}<ColoredTitle>{t("always there")}</ColoredTitle>, {t("reshaping everything")}
            </Title>
            <Content>
                <TextContent>
                <FAQElement
                    title={t("Social life")}
                    text={t("You cancel plans—again—because the pain is too much, and you just can’t fake being okay anymore. People don’t get it because you “don’t look sick.”")}
                    isOpen={isOpenFaq1}
                    toggleFAQ={() => setIsOpenFaq1(!isOpenFaq1)}
                />
                <FAQElement
                    title={t("Professional Life")}
                    text={t("You’re juggling pain, exhaustion, and trying to keep up with everyone else. You worry about being seen as unreliable, even though you’rer trying twice as hard.")}
                    isOpen={isOpenFaq2}
                    toggleFAQ={() => setIsOpenFaq2(!isOpenFaq2)}
                />
                <FAQElement
                    title={t("Fertility")}
                    text={t("Whether you’re trying for a baby or not, the uncertainty can weigh heavy. 50% of infertility cases are connected to chronic conditions.  it’s a heartbreak and constant ‘what-if’ you didn’t ask for but have to carry.")}
                    isOpen={isOpenFaq3}
                    toggleFAQ={() => setIsOpenFaq3(!isOpenFaq3)}
                />
                <FAQElement
                    title={t("Mental Health")}
                    text={t("Your confidence, joy, and energy feel like they’ve disappeared. Endometriosis steals the sense of self-love you once had, leaving you drained, frustrated, and disconnected from the vibrant person you used to be.")}
                    isOpen={isOpenFaq4}
                    toggleFAQ={() => setIsOpenFaq4(!isOpenFaq4)}
                />
                </TextContent>
                <Image 
                    src="/img/impact.png"
                />
            </Content>
            <Citation>
                <CitatonIcon
                    src="/img/svg/citation.svg"
                    width="5%"
                    height="auto"
                />
                <CitationText>
                    {t("I haven’t been able to have a family of my own — no children, no husband, which has strongly affected me emotionally. Because of endometriosis I couldn't get the life I have always been dreaming about")}
                </CitationText>
                <CitatonIcon
                    src="/img/svg/citation.svg"
                    width="5%"
                    height="auto"
                />
            </Citation>
            <Text>{t("Endo Warrior, 20 years with the diagnosis")}</Text>
        </Section>
    )
}

const Section = styled("section")`
    padding: 8em 2.5em;
    margin: 0;
    background: #F2ECFE;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding: 5em 1.5em;
    }
`
const Title = styled("p")`
    color: #1C1C1C;
    font-family: Exo;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1875rem;
    padding: 0;
    margin: 0;
    margin-bottom: 4.25rem;

    @media (max-width: 768px) {
        font-size: 2.25rem;
        margin-bottom: 2.25rem;
    }
    
`
const ColoredTitle = styled('span')`
    font-family: Exo;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1875rem;
    color: #685ADD;

    @media (max-width: 768px) {
        font-size: 2.25rem;
    }
`
const Content = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
`
const TextContent = styled("div")`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 1rem;
`
const Image = styled("img")`
    border-radius: 2.625rem;
    flex-shrink: 0;
    height: fit-content;

    @media (max-width: 768px) {
        display: none;
    }
`
const Citation = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4.25rem;

    @media (max-width: 768px) {
        margin-top: 2.25rem;
    }
`
const CitatonIcon = styled("img")`

    @media (max-width: 768px) {
        width: 3.5%;
    }
`
const CitationText = styled("p")`
    color: #1C1C1C;
    text-align: center;
    font-family: Exo;
    font-size: 2.375rem;
    font-style: italic;
    font-weight: 200;
    line-height: normal;
    padding: 0;
    margin: 2.45rem 0;

    @media (max-width: 768px) {
        font-size: 1.5rem;
        margin: 1.45rem 0;
    }
`
const Text = styled("p")`
    color: #252525;
    text-align: center;
    font-family: Exo;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 2.45rem 0;
    padding: 0;

    @media (max-width: 768px) {
        margin: 1.45rem 0;
    }
`

export default Impact;