import React from "react";
import Intro from "../components/introduction/Intro";
import Partners from "../components/partners/Partners";
import Statistics from "../components/statistics/Statistics";
import Impact from "../components/impact/Impact";
import Journey from "../components/journey/Journey";
import Steps from "../components/steps/Steps";
import Questions from "../components/questions/Questions";
import GetTouch from "../components/getTouch/getTouch";

function Main() {
  return (
      <>
        <Intro />
        <Partners />
        <Statistics />
        <Impact />
        <Journey />
        <Steps />
        <Questions />
        <GetTouch />
      </>
  );
}

export default Main;