import React from "react";
import styled from "styled-components";

function LegalInformation() {
  return (
    <>
      <Container>
        <H1>Informations Légales</H1>
        <Wrapper>
          <Ol>
            <LiOl>
              <LiTitleColored>Identification du professionnel</LiTitleColored>
              <Ul>
                <LiUl>
                  <LiText>Dénomination : ENDOless SAS</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Forme juridique : Société par actions simplifiée à associé unique (SASU)</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Numéro SIREN : 930838990</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Numéro SIRET : 93083899000016</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Capital social : 300 €</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Siège social : 3 Allée Pierrette Anguera-Gargallo, 92130 Issy-les-Moulineaux, France</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Directeur de la publication : Alexandra Mont</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Contact : Pour toute question ou demande d'information, écrivez-nous à contact@endoless.app</LiText>
                </LiUl>
              </Ul>
            </LiOl>

            <LiOl>
              <LiTitleColored>Traitement des données personnelles et utilisation de cookies</LiTitleColored>
              <LiText>Chez ENDOless, la confidentialité de vos données personnelles est une priorité absolue. Nous collectons uniquement les informations nécessaires, telles que votre adresse email, votre tranche d'âge, vos expériences en lien avec l'endométriose et vos préférences.</LiText>
              <LiTitle>Utilisation des données</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>Comprendre vos besoins et expériences.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Améliorer nos services basés sur vos retours.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Vous contacter (avec consentement explicite) pour des interviews ou des mises à jour d'ENDOless.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Réaliser des analyses statistiques sous forme anonyme.</LiText>
                </LiUl>
              </Ul>
              <LiText>Vos données sont conservées pour une durée maximale de 3 mois à compter de leur collecte, sauf si elles sont anonymisées pour des fins de recherche. Une fois cette période écoulée, elles sont supprimées.</LiText>
            </LiOl>

            <LiOl>
              <LiTitleColored>Utilisation des emails et des informations personnelles</LiTitleColored>
              <LiTitle>Utilisation de l'email</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>Vous envoyer des nouvelles et mises à jour de nos services.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Vous proposer des opportunités d'interview.</LiText>
                </LiUl>
              </Ul>
              <LiTitle>Informations requises pour les demandes</LiTitle>
              <Ul>
                <LiUl>
                  <LiText>L'adresse email utilisée pour interagir avec nous.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>La tranche d'âge fournie lors de votre enregistrement.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>La date approximative de votre interaction avec nos services.</LiText>
                </LiUl>
              </Ul>
            </LiOl>

            <LiOl>
              <LiTitleColored>Mesures de sécurité</LiTitleColored>
              <LiText>Nous mettons en œuvre des mesures techniques et organisationnelles strictes pour protéger vos données contre tout accès non autorisé, modification ou perte, notamment :</LiText>
              <Ul>
                <LiUl>
                  <LiText>Le chiffrement des données sensibles.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Des contrôles d'accès rigoureux.</LiText>
                </LiUl>
                <LiUl>
                  <LiText>Des évaluations régulières de sécurité.</LiText>
                </LiUl>
              </Ul>
              <LiText>En cas de questions ou pour exercer vos droits, contactez-nous à contact@endoless.app.</LiText>
            </LiOl>
          </Ol>
        </Wrapper>
      </Container>
    </>
  );
}

const Container = styled("section")`
  position: relative;
  margin-top: 9rem;
  margin-bottom: 4rem;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  padding: 0px 60px;
`

const Wrapper = styled("div")``

const H1 = styled("h1")``

const Ol = styled("ol")``

const LiOl = styled("li")`
  margin-bottom: 1.5rem;
`

const Ul = styled("ul")``

const LiUl = styled("li")``

const LiTitle = styled("p")`
  font-weight: bolder;
  margin-bottom: .5rem;
`

const LiTitleColored = styled(LiTitle)`
  color: #685ADD;
`

const LiText = styled("p")`
  font-weight: lighter;
  margin-bottom: .5rem;
`

export default LegalInformation;