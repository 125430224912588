import React, { createContext, useState, useContext, useEffect } from "react";
import { translations } from "../translations/translations";

const LocalizationContext = createContext()

const getDefaultLanguage = () => {
    const userLang = navigator.language.split("-")[0]
    return translations[userLang] ? userLang : "en"
  }

export const LocalizationProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem("language") || getDefaultLanguage());
    
    const changeLanguage = (lang) => {
        setLanguage(lang);
        localStorage.setItem("language", lang)
    }

    useEffect(() => {
        localStorage.setItem("language", language)
    }, [language])
    
    const t = (key) => translations[language][key] || key

  return (
    <LocalizationContext.Provider value={{ language, setLanguage: changeLanguage, t }}>
      {children}
    </LocalizationContext.Provider>
  );
}

export const useLocalization = () => useContext(LocalizationContext);
